<template lang="pug">
    .main-wrapper.empresa-salvar
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "empresas" }'> Empresas</router-link> /
                            <b>{{ model.id ? 'Editar' : 'Adicionar' }}</b>

            Dialog.dialogApagar(header='Remover paciente' :visible.sync='dialogApagar' :modal='true')
                p Deseja remover o paciente <b>{{ dialogApagar_data.nm_pessoa_fisica }}</b> desta empresa?
                .ta-center.my-4
                    ProgressSpinner.small-spinner(v-if='waitingApagar' strokeWidth='6')
                    Button.p-button-danger(v-else label='Remover' @click='removerPaciente(dialogApagar_data)')

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waitingForm || waiting' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } empresas`")
                        .p-grid.p-fluid.p-align-end

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nm_razao_social.$error }")
                                label.form-label Razão social:
                                InputText(type='text' v-model='$v.model.nm_razao_social.$model' readonly)
                                .feedback--errors(v-if='submitted && $v.model.nm_razao_social.$error')
                                    .form-message--error(v-if="!$v.model.nm_razao_social.minLength") <b>Razão Social</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nm_razao_social.required") <b>Razão Social</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_cnpj.$error }")
                                label.form-label CNPJ:
                                InputMask(mask='99.999.999/9999-99' v-model='$v.model.nr_cnpj.$model' readonly)
                                .feedback--errors(v-if='submitted && $v.model.nr_cnpj.$error')
                                    .form-message--error(v-if="!$v.model.nr_cnpj.minLength") <b>CNPJ</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nr_cnpj.required") <b>CNPJ</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nm_fantasia.$error }")
                                label.form-label Nome fantasia:
                                InputText(type='text' v-model='$v.model.nm_fantasia.$model' readonly)
                                .feedback--errors(v-if='submitted && $v.model.nm_fantasia.$error')
                                    .form-message--error(v-if="!$v.model.nm_fantasia.minLength") <b>Nome Fantasia</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nm_fantasia.required") <b>Nome Fantasia</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nm_responsavel.$error }")
                                label.form-label Responsável:
                                InputText(type='text' v-model='$v.model.nm_responsavel.$model' readonly)
                                .feedback--errors(v-if='submitted && $v.model.nm_responsavel.$error')
                                    .form-message--error(v-if="!$v.model.nm_responsavel.minLength") <b>Responsável</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nm_responsavel.required") <b>Responsável</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nm_bairro.$error }")
                                label.form-label Bairro:
                                InputText(type='text' v-model='$v.model.nm_bairro.$model' readonly)
                                .feedback--errors(v-if='submitted && $v.model.nm_bairro.$error')
                                    .form-message--error(v-if="!$v.model.nm_bairro.minLength") <b>Bairro</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nm_bairro.required") <b>Bairro</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nm_rua.$error }")
                                label.form-label Rua:
                                InputText(type='text' v-model='$v.model.nm_rua.$model' readonly)
                                .feedback--errors(v-if='submitted && $v.model.nm_rua.$error')
                                    .form-message--error(v-if="!$v.model.nm_rua.minLength") <b>Rua</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nm_rua.required") <b>Rua</b> é obrigatório.

                            .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.ds_complemento.$error }")
                                label.form-label Complemento:
                                InputText(type='text' v-model='$v.model.ds_complemento.$model' readonly)

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_cep.$error }")
                                label.form-label CEP:
                                InputMask(mask='99999-999' v-model='$v.model.nr_cep.$model' readonly)
                                .feedback--errors(v-if='submitted && $v.model.nr_cep.$error')
                                    .form-message--error(v-if="!$v.model.nr_cep.minLength") <b>CEP</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nr_cep.required") <b>CEP</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_empresa.$error }")
                                label.form-label Número da Empresa:
                                InputText(type='text' v-model='$v.model.nr_empresa.$model' readonly)
                                .feedback--errors(v-if='submitted && $v.model.nr_empresa.$error')
                                    .form-message--error(v-if="!$v.model.nr_empresa.minLength") <b>Número da Empresa</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nr_empresa.required") <b>Número da Empresa</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_telefone.$error }")
                                label.form-label Telefone:
                                InputMask(:mask="'(99) 99999999?9'" v-model='$v.model.nr_telefone.$model' readonly)
                                .feedback--errors(v-if='submitted && $v.model.nr_telefone.$error')
                                    .form-message--error(v-if="!$v.model.nr_telefone.minLength") <b>Telefone</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nr_telefone.required") <b>Telefone</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.ds_email.$error }")
                                label.form-label Email:
                                InputText(type='text' v-model='$v.model.ds_email.$model' readonly)
                                .feedback--errors(v-if='submitted && $v.model.ds_email.$error')
                                    .form-message--error(v-if="!$v.model.ds_email.minLength") <b>Email</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.ds_email.required") <b>Email</b> é obrigatório.

                            .p-col-12.mt-2.mb-2(v-if="model.id")
                                .p-grid
                                    .p-col-12.p-md-6.p-lg-3
                                        span.d-block.o-hidden <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                                    .p-col-12.p-md-6.p-lg-3
                                        span <b>Data da criação: </b> {{ model.dt_criado_f }}
                                    .p-col-12.p-md-6.p-lg-3
                                        span.d-block.o-hidden <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                                    .p-col-12.p-md-6.p-lg-3
                                        span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                            //- .p-col-12.ta-right
                            //-     Button(label='Salvar' type="submit" style='max-width:160px')

                // BuscarPaciente(ref='buscaPaciente' :hideSearchForm='true')

                ProgressBar(v-if='waitingPacientes || waiting' mode="indeterminate")
                div(v-else)
                    .ta-right.mt-2
                        Button(v-if="model.id && !dialogPaciente" label='Adicionar' type="button" icon="jam jam-plus" @click="dialogPaciente = true")

                    Panel.adicionar-paciente(v-if="dialogPaciente" header="Adicionar cliente")
                        BuscarPaciente(ref='buscarPaciente' label='Cliente' :somenteTitular='true' :hideCadastro='false' :modoEmpresa='true' :cancelarPopup='true')
                        .ta-center.mt-2
                            ProgressSpinner.small-spinner(v-if='waitingNomeUsuario' strokeWidth='5')
                            div(v-else)
                                Button.p-button-success.mr-2(label='Adicionar' type='button' icon='jam jam-check' @click='vincularPaciente()')
                                Button.p-button-danger(label='Cancelar' type="button" icon="jam jam-close" @click="dialogPaciente = false")

                    Panel.datatable.mt-3(header='Lista de clientes' v-if="model.id")
                        Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
                        DataTable(:value="listaPacientes")
                            Column(headerStyle='width: 25%;' field='nm_pessoa_fisica' header='Nome')
                            Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='nr_cpf_f' header='CPF')
                            Column(headerStyle='width: 12%;' bodyStyle='text-align: center;' field='dt_nascimento_f' header='Data de nascimento')
                            Column(headerStyle='width: 8%;' bodyStyle='text-align: center;' field='ie_sexo_f' header='Sexo')
                            Column(headerStyle='width: 12%;' bodyStyle='text-align: center;' field='nr_telefone_f' header='Telefone')
                            Column(headerStyle='width: 18%;' header='E-mail')
                                template(#body='props')
                                    span(v-tooltip.top="props.data.ds_email" style='cursor: pointer;') {{ props.data.ds_email_f }}
                            Column(headerStyle='width: 8%; text-align: center')
                                template(#header) Ações
                                template(#body='props')
                                    .ta-center
                                        Button.p-button-raised.p-button-rounded.mr-1(
                                            v-tooltip.top="'Editar'"
                                            type="button"
                                            icon="jam jam-write"
                                            @click="$router.push(`/paciente/salvar/${ props.data.id }/`)"
                                        )
                                        Button.p-button-raised.p-button-rounded.p-button-danger(
                                            v-tooltip.top="'Apagar'"
                                            type="button"
                                            icon="jam jam-minus-circle"
                                            @click="dialogApagar = true; dialogApagar_data = props.data"
                                        )
                        Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

</template>

<style lang="scss">
    .empresa-salvar {

        .waitingEmpresas-wrapper {
            text-align: center;
            padding: 80px 0;
            .p-progress-spinner {
                width: 60px;
                height: auto;
            }
        }
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }

        .p-dialog{overflow:visible;width:98%;max-width:600px}
        .p-dialog .p-dialog-content{overflow:visible !important}
        .small-spinner {
            width: 29px;
            height: auto;
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import ProgressSpinner from 'primevue/progressspinner'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import PickList from 'primevue/picklist'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Paginator from 'primevue/paginator'
    import Dialog from 'primevue/dialog'
    import moment from 'moment'
    import BuscarPaciente from '../Paciente/BuscarPaciente'



    import { Empresa } from './../../middleware'
    import wsConfigs from './../../middleware/configs'
    import { fixTelefoneFormat } from './../../utils'
    import { required, minLength } from 'vuelidate/lib/validators'

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, ProgressSpinner,
                InputMask, SelectButton, Dropdown, Tooltip, PickList, DataTable, Column,
                Paginator, Dialog, BuscarPaciente
        },
        directives: { tooltip: Tooltip },
        created () {
            this.waiting = true
            Empresa.getDados().then(response => {
                if (response.status == 200) {
                    let keys = Object.keys(this.model)
                    keys.forEach(key => this.model[key] = response.data[key])
                    this.model.id = response.data.id
                    this.model.dt_criado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm')
                    if (response.data.dt_atualizado) {
                        this.model.dt_atualizado_f = moment(response.data.dt_atualizado).format('DD/MM/YYYY HH:mm')
                    }
                    this.loadPacientes(response.data.id)
                }
                this.waiting = false
            })
        },
        data () {
            return {
                model: {
                    nm_razao_social: null,
                    nm_fantasia: null,
                    nr_cnpj: null,
                    nm_responsavel: null,
                    nm_bairro: null,
                    nm_rua: null,
                    ds_complemento: null,
                    nr_cep: null,
                    nr_empresa: null,
                    nr_telefone: null,
                    nr_whatsapp: null,
                    ds_email: null,
                    nm_usuario_cri: null,
                    nm_usuario_edi: null
                },
                filters: {
                    procedimentos: ''
                },
                options: {
                    especialidades: [],
                    procedimentos: []
                },
                waiting: true,
                waitingEspecialidades: false,
                waitingForm: false,
                waitingApagar: false,
                waitingPacientes: false,
                waitingNomeUsuario: false,
                submitted: false,
                dialogApagar: false,
                dialogApagar_data: {},
                dialogPaciente: false,
                listaPacientes: [],
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                }
            }
        },
        validations () {
            let v = {
                model: {
                    nm_razao_social: { required, minLength: minLength(2) },
                    nm_fantasia: { required, minLength: minLength(2) },
                    nr_cnpj: { required, minLength: minLength(2) },
                    nm_responsavel: { required, minLength: minLength(2) },
                    nm_bairro: { required, minLength: minLength(2) },
                    nm_rua: { required, minLength: minLength(2) },
                    ds_complemento: {  },
                    nr_cep: { required, minLength: minLength(2) },
                    nr_empresa: { required, minLength: minLength(2) },
                    nr_telefone: { required, minLength: minLength(2) },
                    nr_whatsapp: { minLength: minLength(2) },
                    ds_email: { required, minLength: minLength(2) },
                }
            }

            return v
        },
        methods: {
            loadPacientes(id){
                this.waitingPacientes = true
                Empresa.listarAssociados({cd_empresa: id }).then( response => {
                    if (response.status == 200) {
                        response.data.forEach(paciente => {
                            paciente.dt_nascimento_f = moment(paciente.dt_nascimento).format('DD/MM/YYYY')
                            paciente.ie_sexo_f = ({ M: 'Masculino', F: 'Feminino' })[paciente.ie_sexo]
                            if (paciente.nr_telefone) paciente.nr_telefone_f = fixTelefoneFormat(paciente.nr_telefone)
                            if (paciente.nr_cpf) paciente.nr_cpf_f = paciente.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                            if (paciente.ds_email) {
                                let strMaxLength = 24
                                paciente.ds_email_f = paciente.ds_email.length > strMaxLength ?
                                    `${ paciente.ds_email.substr(0, strMaxLength) }...` : paciente.ds_email
                            }
                        })
                        this.listaPacientes = response.data
                        this.paginator.count = response.data.count
                    }
                    this.waitingPacientes = false
                })
            },
            handleSubmit () {
                this.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = Object.assign({}, this.model)

                let arrConvertOnlyNumbers = ['nr_cnpj', 'nr_telefone', 'nr_whatsapp', 'nr_cep']
                arrConvertOnlyNumbers.forEach(i => { if (dataSend[i]) dataSend[i] = dataSend[i].match(/\d+/g).join('') })

                this.waitingForm = true
                Empresa.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        if (! dataSend.id) this.$router.push({ name: 'empresas' })
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                    } else {
                        if (response.data.non_field_errors)
                            response.data.non_field_errors.forEach(errorMsg => {
                                this.$toast.error(errorMsg, { duration: 3000 })
                            })
                        else Object.values(response.data).forEach(errorMsg => {
                                if (typeof errorMsg == 'object')
                                    this.$toast.error(errorMsg[0], { duration: 3000 })
                                else this.$toast.error(errorMsg, { duration: 3000 })
                            })
                    }
                    this.waitingForm = false
                })
            },
            removerPaciente(props){
                this.waitingApagar = true
                let dataSend = {
                        cd_pessoa_fisica: props.id,
                        cd_empresa: this.model.id
                }

                Empresa.removerAssociado(dataSend).then(response => {
                    if ([200, 201, 204].includes(response.status)) {
                        this.$toast.success('Cadastro removido com sucesso', { duration: 3000 });
                        this.loadPacientes(this.model.id);
                        this.dialogApagar = false;

                    } else if (([400]).includes(response.status)) {
                        if (response.data)
                            Object.values(response.data).forEach(error => {
                                error.forEach(errorMsg => this.$toast.error(errorMsg, { duration: 3000 }))
                            })
                    }
                    this.waitingApagar = false
                })
            },
            vincularPaciente(){

                if ( !this.$refs.buscarPaciente.cd_pessoa_fisica ) {
                    this.$toast.error('Selecione um cliente', { duration: 3000 })
                    return 0
                }

                let props = this.$refs.buscarPaciente.model

                if ( props.cd_empresa != null  ) {
                    this.$toast.error('Este cliente já pertence a uma empresa', { duration: 3000 })
                    return 0
                }

                this.waitingNomeUsuario = true
                let dataSend = {
                        cd_pessoa_fisica: props.id,
                        cd_empresa: this.model.id,
                    }

                // let arrConvertOnlyNumbers = ['nr_telefone']
                // arrConvertOnlyNumbers.forEach(i => { if (dataSend[i]) dataSend[i] = dataSend[i].match(/\d+/g).join('') })
                // dataSend.nr_telefone = `+55${ dataSend.nr_telefone }`

                Empresa.associarUsuario(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        this.$toast.success('Cadastro adicionado com sucesso', { duration: 3000 })
                        this.loadPacientes(this.model.id)
                        this.dialogPaciente = false;

                    } else if (([400]).includes(response.status)) {
                        if (response.data)
                            Object.values(response.data).forEach(error => {
                                error.forEach(errorMsg => this.$toast.error(errorMsg, { duration: 3000 }))
                            })
                    }
                    this.waitingNomeUsuario = false
                })
            }
        }
    }
</script>
